import { useNavigation } from '@remix-run/react';

function getNavigation(navigation: ReturnType<typeof useNavigation>) {
	const isGetRequest = navigation.formMethod !== undefined && navigation.formMethod === 'GET';
	const samePage = navigation.formAction === (navigation.location && `${navigation.location.pathname}${navigation.location.search}`);
	const isFetcherActionRedirect = () => {
		const state = navigation.location?.state;
		const isRedirect = (state && '_isRedirect' in state);
		const isFetchActionRedirect = (state && '_isFetchActionRedirect' in state);

		return Boolean(isRedirect && isFetchActionRedirect);
	};

	/**
	 * Actions
	 */
	const isSubmission = () => navigation.state === 'submitting' && !isGetRequest;
	const isRefresh = () => navigation.state === 'loading' && !isGetRequest && samePage;
	const isSubmissionRedirect = () => navigation.state === 'loading' && !isGetRequest && !samePage;

	/**
	 * Loaders
	 */
	const isLoading = () => (
		navigation.state === 'loading' && (navigation.formMethod === undefined || navigation.formMethod === 'GET')
	);

	const isLoadingRedirect = () => navigation.state === 'loading' && isGetRequest && !samePage;

	/**
	 * Catch all
	 */
	const isProcessing = () => navigation.state !== 'idle';

	return {
		isIdle: navigation.state === 'idle',
		/**
		 * Actions
		 */
		isSubmission: isSubmission(),
		isRefresh: isRefresh(),
		isSubmissionRedirect: isSubmissionRedirect(),

		/**
		 * Loaders
		 */
		isLoading: isLoading(),
		isLoadingRedirect: isLoadingRedirect(),

		/**
		 * Catch all
		 */
		isProcessing: isProcessing() && !isFetcherActionRedirect(),
	};
}

export { getNavigation as default, getNavigation };
